import Container from '@mui/material/Container';
import NoDataContainer from '@/components/NoDataContainer';
import TableSkeleton from '@/components/TableSkeleton';
import BudgetHeader from '@/pages/finances/budget/BudgetHeader';
import BudgetHistoryDrawer from '@/pages/finances/budget/BudgetHistoryDrawer';
import BudgetFilter from '../BudgetFilter';
import BudgetTable from '../BudgetTable';
import { noDataSelector } from './selector';
import useStyles from './styles';

const BudgetRoot = () => {
  const styles = useStyles();

  return (
    <Container maxWidth="xl" sx={styles.container}>
      <BudgetHeader />

      <BudgetFilter />

      <NoDataContainer sx={styles.noDataContainer} selector={noDataSelector} loader={<TableSkeleton rows={10} />}>
        <BudgetTable />
      </NoDataContainer>

      <BudgetHistoryDrawer />
    </Container>
  );
};

export default BudgetRoot;
