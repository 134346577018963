import Button from '@core-ui/button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ChunkLoader from '@/components/ChunkLoader';
import NoDataContainer from '@/components/NoDataContainer';
import { useAppSelector } from '@/hooks/useAppSelector';
import BudgetHistoryItem from '@/pages/finances/budget/BudgetHistoryDrawer/BudgetHistoryItem';
import { X } from '@phosphor-icons/react';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { getBudgetHistory, getChunkBudgetHistory, setDrawerOpened } from './actions';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';
import useStyles from './styles';

const BudgetHistoryDrawer: FC = () => {
  const { items, isOpened } = useAppSelector(selector);

  const styles = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // fetch budget history on drawer open only when there are no items
    if (isOpened && !(items && items.length > 0)) {
      dispatch(getBudgetHistory(null));
    }
  }, [isOpened]);

  const onClose = () => {
    dispatch(setDrawerOpened(false));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpened}
      onClose={onClose}
      PaperProps={{
        sx: styles.drawer,
      }}
    >
      <Grid container direction="column" gap={2}>
        <Grid item container paddingX={4} paddingY={6} component={Paper}>
          <Button variant="outlined" onClick={onClose} sx={styles.closeButton} icon={<X />} />
          <Typography variant="h5" alignSelf="center" marginLeft={19.5}>
            <FormattedMessage id="label.activity_feed" />
          </Typography>
        </Grid>

        <NoDataContainer direction="column" gap={2} selector={getNoDataContainerData}>
          {items?.map((item) => (
            <BudgetHistoryItem item={item} key={item.id} />
          ))}

          <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkBudgetHistory} />
        </NoDataContainer>
      </Grid>
    </Drawer>
  );
};

export default BudgetHistoryDrawer;
