import { getChunkList, getList, resetState, setChunkList, setList } from '@core-ui/reducers_handlers';
import { BudgetHistorySchema } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'budget.history';

export const getBudgetHistory = getList(reducerName);
export const setBudgetHistory = setList<BudgetHistorySchema>(reducerName);

export const getChunkBudgetHistory = getChunkList(reducerName);
export const setChunkBudgetHistory = setChunkList<BudgetHistorySchema>(reducerName);

export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const resetBudgetHistory = resetState(reducerName);

export const setDrawerOpened = createAction<boolean>(`${reducerName}.SET_DRAWER_OPENED`);
