import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { DRAWER_GRID_ITEM_WIDTH, DRAWER_WIDTH } from '@/pages/backoffice/Ledger/consts';

export default (): Record<string, SxProps<Theme>> => ({
  drawer: {
    width: DRAWER_WIDTH,
    backgroundColor: COLOURS.Coconut.Solid[100],
  },
  closeButton: {
    backgroundColor: `${COLOURS.Coconut.Solid[100]} !important`,
    borderColor: 'transparent !important',

    '&:hover': {
      borderColor: `${COLOURS.Coconut.Solid[500]} !important`,
    },
  },
  gridItem: {
    maxWidth: `${DRAWER_GRID_ITEM_WIDTH}px !important`,
  },
});
