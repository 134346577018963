import { useParams } from '@core-ui/redux-router';
import { BREAKPOINTS } from '@core-ui/styles';
import Tabs from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { ROUTES } from '@/app/consts/routes';
import { FINANCES_TABS } from '@/pages/finances/consts';
import { FINANCES_PATHS } from '@/pages/finances/routes';
import { FinancesPathParams } from '@/pages/finances/types';
import * as React from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from './styles';

const FinancesTabs: FC = () => {
  const { boatId, financeTab } = useParams<FinancesPathParams>(FINANCES_PATHS);
  const styles = useStyles();

  const tabs = [
    {
      value: FINANCES_TABS.BUDGET,
      label: <FormattedMessage id="tab.budget" />,
      dataTestId: 'budget-tab',
      to: buildURL(`/${boatId}/${ROUTES.FINANCES}/${FINANCES_TABS.BUDGET}`),
    },
  ];

  return (
    <Paper elevation={0} sx={styles.paper}>
      <Grid item marginTop="auto" padding="0 24px" width="100%" maxWidth={BREAKPOINTS.XL}>
        <Tabs size="small" tabs={tabs} active={financeTab} />
      </Grid>
    </Paper>
  );
};

export default FinancesTabs;
