import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from '@/app/types/common';
import { monthItemWidth } from '@/pages/finances/consts';
import { IBudgetForm } from '@/pages/finances/types';
import { FormState } from 'final-form';
import { useMemo } from 'react';
import { useFormSelector } from 'react-final-form-selector';
import { formSelector } from './selector';
import useStyles from './styles';

interface IProps {
  path: string;
  amountFormatter: AmountFormatter;
}

const TotalMonthCell = (props: IProps) => {
  const { path, amountFormatter } = props;

  const memoizedFormSelector = useMemo(formSelector, []);
  const { totalBudget } = useFormSelector((state: FormState<IBudgetForm>) => memoizedFormSelector(state, { path }));

  const styles = useStyles();

  return (
    <Grid position="relative">
      <Grid container sx={styles.totalCell} alignItems="center" width={monthItemWidth}>
        <Typography paddingLeft={2}>{amountFormatter(totalBudget)}</Typography>
      </Grid>
    </Grid>
  );
};

export default TotalMonthCell;
