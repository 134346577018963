import { resetState, setLoading } from '@core-ui/reducers_handlers';
import { getData, setData } from '@core-ui/reducers_handlers/actions';
import { Nullable } from '@core-ui/types';
import { ReadBudgetOutputSchema, ReadClientArticlesBudgets, TotalBudget } from '@/generated';
import { createAction } from 'redux-actions';
import { IBudget, IBudgetForm } from '../../types';

export const reducerName = 'budget.list';

export const getBudget = getData(reducerName);
export const setBudget = setData<ReadBudgetOutputSchema | ReadClientArticlesBudgets>(reducerName);

export const setBudgetLoading = setLoading(reducerName);

export const setBudgetForm = createAction<Nullable<IBudgetForm>>(`${reducerName}.SET_BUDGET_FORM`);

export const setHeadersList = createAction<any>(`${reducerName}.SET_HEADERS_LIST`);

export const saveBudget = createAction<IBudget>(`${reducerName}.SAVE_BUDGET`);

// TODO: этот экшен и сага не используются, но пока что не удаляем т.к. могут потребоваться
export const saveTotalMonthBudget = createAction<TotalBudget>(`${reducerName}.SAVE_TOTAL_BUDGET`);

export const resetBudgetList = resetState(reducerName);

export const updateCostArticlesMappingFromBudgets = createAction(
  `${reducerName}.UPDATE_COST_ARTICLES_MAPPING_FROM_BUDGETS`
);
