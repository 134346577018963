import budgetsHeader, { IBudgetHeaderState } from '@/pages/finances/budget/BudgetHeader/reducer';
import budgetHistoryDrawer, { IBudgetHistoryDrawerList } from '@/pages/finances/budget/BudgetHistoryDrawer/reducer';
import { combineReducers } from 'redux';
import budgetsFilter, { IBudgetFiltersState } from './budget/BudgetFilter/reducer';
import budgets, { IBudgetsState } from './budget/BudgetRoot/reducer';

export interface IFinancesState {
  budgets: IBudgetsState;
  budgetsHeader: IBudgetHeaderState;
  budgetsFilter: IBudgetFiltersState;
  budgetHistoryDrawer: IBudgetHistoryDrawerList;
}

export default combineReducers({
  budgets,
  budgetsHeader,
  budgetsFilter,
  budgetHistoryDrawer,
});
