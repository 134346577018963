import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { getEndOfCurrentYearInUnix, getStartOfCurrentYearInUnix } from '@/app/utils/dates';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IBudgetFiltersState {
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  loading: boolean;
}

const defaultState = Immutable<IBudgetFiltersState>({
  dateFrom: String(getStartOfCurrentYearInUnix()),
  dateTo: String(getEndOfCurrentYearInUnix()),
  loading: false,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
