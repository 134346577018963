import State from '@/app/types/state';
import { createSelector } from 'reselect';

export const getFiltersSelector = (state: State) => state.finances.budgetsFilter;

const getLoading = (state: State) => state.finances.budgetsFilter.loading;

export default createSelector([getFiltersSelector, getLoading], (filters, loading) => ({
  filters,
  loading,
}));
