import { convertValueToUTCDate } from '@core-ui/dates/dates';
import { Nullable } from '@core-ui/types';
import {
  addMonths,
  dateToDayStartUTC,
  eachMonthOfInterval,
  endOfYear,
  formatTime,
  startOfYear,
} from '@/app/utils/dates';

export interface IInterval {
  name: string;
  year: number;
  month: number;
}

const now = new Date();

export const makeInterval = (dateFrom: Nullable<string>, dateTo: Nullable<string>): IInterval[] => {
  return eachMonthOfInterval({
    // we add 1 month to the dates because without it eachMonthOfInterval generates months with an offset of 1 month ago
    start: dateFrom ? convertValueToUTCDate(+dateFrom * 1000) : addMonths(startOfYear(dateToDayStartUTC(now)), 1),
    end: dateTo ? convertValueToUTCDate(+dateTo * 1000) : addMonths(endOfYear(dateToDayStartUTC(now)), 1),
  }).map((date) => {
    const utcDate = dateToDayStartUTC(date);

    return {
      name: formatTime(utcDate, { dateFormat: 'MMMM-yy' }),
      year: +formatTime(utcDate, { dateFormat: 'yyyy' }),
      month: +formatTime(utcDate, { dateFormat: 'MM' }),
    };
  });
};
