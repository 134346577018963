import { IBudgetForm } from '@/pages/finances/types';
import { FormState } from 'final-form';
import get from 'lodash-es/get';
import { createSelector } from 'reselect';

const getRow = (state: FormState<IBudgetForm>, props: { path: string }) => {
  return get(state.values, props.path) as number;
};

export const formSelector = () =>
  createSelector([getRow], (totalBudget) => ({
    totalBudget,
  }));
