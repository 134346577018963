import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from '@/app/types/common';
import { useAppSelector } from '@/hooks/useAppSelector';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'components/Intl';
import TotalMonthCell from '../TotalMonthCell';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  amountFormatter: AmountFormatter;
}

const BudgetHeader = (props: IProps) => {
  const { amountFormatter } = props;

  const { headers, total } = useAppSelector(selector);
  const styles = useStyles();

  return (
    <Grid
      container
      wrap="nowrap"
      flexDirection="column"
      top={0}
      position="sticky"
      zIndex={2}
      sx={styles.container}
    >
      <Grid container wrap="nowrap">
        <Grid container sx={styles.costArticleContainer}>
          <Grid item sx={styles.costArticleColor} />

          <Grid item container sx={styles.costArticleName} alignItems="center">
            <Typography variant="body1" fontWeight="semiBold" paddingLeft={2}>
              <FormattedMessage id="cost.article" />
            </Typography>
          </Grid>
        </Grid>

        {headers?.map((header) => (
          <Grid item container key={header.name} sx={styles.monthName} alignItems="center">
            <Typography variant="body1" fontWeight="semiBold" paddingLeft={2}>
              {header.name}
            </Typography>
          </Grid>
        ))}

        <Grid item container sx={styles.totalNameHeader} alignItems="center" justifyContent="flex-end">
          <Typography variant="body1" fontWeight="semiBold" paddingRight={3} align="right">
            <FormattedMessage id="label.total" />
          </Typography>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap">
        <Grid container sx={styles.totalContainer}>
          <Grid item sx={styles.totalColor} />

          <Grid item container sx={styles.totalName} alignItems="center">
            <Typography variant="body1" paddingLeft={2}>
              <FormattedMessage id="table.header.total" />
            </Typography>
          </Grid>
        </Grid>

        <FieldArray
          name="monthSubtotal"
          subscription={{ length: true }}
          render={({ fields }) => {
            return fields.map((totalPathValues) => (
              <TotalMonthCell key={totalPathValues} path={totalPathValues} amountFormatter={amountFormatter} />
            ));
          }}
        />

        <Grid item container sx={styles.totalCell} alignItems="center" justifyContent="flex-end">
          <Typography variant="body1" paddingRight={3} align="right">
            {total}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BudgetHeader;
