import Button from '@core-ui/button';
import DatePicker, { RangePickerProps } from '@core-ui/datepicker';
import { useFilter } from '@core-ui/hooks';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Clock } from '@phosphor-icons/react';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'components/Intl';
import { batch, useDispatch } from 'react-redux';
import { MONTH_YEAR_DATE_FORMAT, RANGE_PICKER_LABELS } from '@/app/consts/app';
import { IRangeFilterPreset } from '@/app/types/common';
import {
  addMonths,
  dateToDayEndUTC,
  dateToDayStartUTC,
  dateToUTC,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
} from '@/app/utils/dates';
import { useAppSelector } from '@/hooks/useAppSelector';
import { setDrawerOpened } from '@/pages/finances/budget/BudgetHistoryDrawer/actions';
import { getBudget, setBudgetLoading } from '../BudgetRoot/actions';
import { setTextFilter } from './actions';
import { IBudgetFiltersState } from './reducer';
import selector from './selector';

const BudgetFilter: FC = () => {
  const { filters } = useAppSelector(selector);

  const dispatch = useDispatch();
  const intl = useIntl();

  const setLoadingCallback = useCallback(() => dispatch(setBudgetLoading({ loading: true })), [setBudgetLoading]);

  const handleFilterChange = useFilter<IBudgetFiltersState>({
    getList: getBudget,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    batch(() => {
      handleFilterChange('dateFrom')(dates?.[0] ? dateToDayStartUTC(startOfMonth(dates[0])) : null);
      handleFilterChange('dateTo')(dates?.[1] ? dateToDayEndUTC(endOfMonth(dates[1])) : null);
    });
  };

  const handleOpenHistoryDrawer = () => {
    dispatch(setDrawerOpened(true));
  };

  const nowDate = new Date();
  const presets: IRangeFilterPreset[] = [
    {
      label: intl.formatMessage({ id: 'label.month' }),
      value: [startOfMonth(addMonths(nowDate, -1)), endOfMonth(nowDate)],
    },
    {
      label: intl.formatMessage({ id: 'label.year' }),
      value: [startOfMonth(addMonths(nowDate, -11)), endOfMonth(nowDate)],
    },
    {
      label: intl.formatMessage({ id: 'label.current_year' }),
      value: [startOfYear(nowDate), endOfYear(nowDate)],
    },
  ];

  const pickerValue: [Nullable<Date>, Nullable<Date>] = [null, null];

  if (filters.dateFrom) {
    pickerValue[0] = dateToUTC(+filters.dateFrom);
  }

  if (filters.dateTo) {
    pickerValue[1] = dateToUTC(+filters.dateTo);
  }

  return (
    <Grid container wrap="nowrap" paddingY={6} gap={4}>
      <Grid item>
        <DatePicker<RangePickerProps>
          presets={presets}
          dateTimeFormat={MONTH_YEAR_DATE_FORMAT}
          value={pickerValue}
          onChange={handleDateChange}
          label={RANGE_PICKER_LABELS}
          openOnFocus
          size="medium"
          picker="month"
          view="rangepicker"
          data-testid="budget-filter-rangepicker"
        />
      </Grid>

      <Grid
        container
        wrap="nowrap"
        gap={2}
        marginLeft="auto"
        width="auto"
      >
        <Tooltip title={<FormattedMessage id="tooltip.activity_feed" />} placement="top" arrow>
          <div>
            <Button color="blue" variant="outlined" size="large" icon={<Clock />} onClick={handleOpenHistoryDrawer} />
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default BudgetFilter;
