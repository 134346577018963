import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { monthItemWidth } from '@/pages/finances/consts';

const border = `1px solid ${COLOURS.BORDER.MAIN}`;
const offset = 10;

export default (props: { isCellFieldActive: boolean }) => ({
  totalCell: {
    borderBottom: border,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: props.isCellFieldActive ? COLOURS.BACKGROUND.MAIN : COLOURS.Coconut.Solid[100],
    cursor: props.isCellFieldActive ? 'pointer' : 'initial',
    color: COLOURS.Coconut.Solid[900],
  },
  totalActive: {
    position: 'absolute',
    top: 0,
    left: -offset / 2,
    zIndex: 1,

    '.MuiOutlinedInput-root': {
      width: monthItemWidth + offset,
      height: HEIGHT_SIZE.headerHeight,
    },
  },
});
