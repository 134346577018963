import FinancesList from '@/pages/finances/FinancesList';
import FinancesTabs from '@/pages/finances/FinancesTabs';

const FinancesRoot = () => {
  return (
    <>
      <FinancesTabs />

      <FinancesList />
    </>
  );
};

export default FinancesRoot;
