import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from '@/app/types/common';
import { memo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import MonthCell from '../MonthCell';
import useStyles from './styles';

interface IProps {
  rowPath: string;
  amountFormatter: AmountFormatter;
  total: number;
  color: string;
  nameArticle: string;
  isRootRow: boolean;
}

const BudgetRow = (props: IProps) => {
  const { amountFormatter, total, color, rowPath, nameArticle, isRootRow } = props;
  const styles = useStyles({ color, isRootRow });

  return (
    <Grid container>
      <Grid container wrap="nowrap">
        <Grid container sx={styles.costArticleContainer}>
          <Grid item sx={styles.costArticleColor} />

          <Grid item container sx={styles.costArticleName} alignItems="center" paddingLeft={2} gap={2}>
            <Typography variant="body1">{nameArticle}</Typography>
          </Grid>
        </Grid>

        <FieldArray
          name={`${rowPath}.month_values`}
          subscription={{ length: true }}
          render={({ fields }) => {
            return fields.map((rowPath) => (
              <MonthCell key={rowPath} rowPath={rowPath} isEditable={!isRootRow} amountFormatter={amountFormatter} />
            ));
          }}
        />

        <Grid item sx={styles.total}>
          <Typography variant="body1" paddingTop={4} paddingRight={3} overflow="hidden" align="right">
            {amountFormatter(total)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(BudgetRow);
